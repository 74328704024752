import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppDB } from 'src/app/config/idb';
import { EInspectionStepUrl } from 'src/app/interfaces/PWA/Inspection';
import { OrientationService } from 'src/app/services/General/Orientation/orientation.service';
import { InspectionControlService } from 'src/app/services/InspectionHandlers/InspectionControl/inspection-control.service';
import { ProgressService } from 'src/app/services/InspectionHandlers/Progress/progress.service';
import { StepManagerService } from 'src/app/services/InspectionHandlers/StepManager/step-manager.service';
import { TourManagerService } from 'src/app/services/InspectionHandlers/TourManager/tour-manager.service';
import { RouteSharedDataService } from 'src/app/services/Workarounds/RouteSharedData/route-shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionInitResolverService {
  // newData = false;

  constructor(
    private readonly inspectionControlService: InspectionControlService,
    private readonly stepManagerService: StepManagerService,
    private readonly progressService: ProgressService,
    private readonly routeSharedDataService: RouteSharedDataService,   
    private readonly tourManagerService: TourManagerService,
    private readonly DB: AppDB,
    private readonly router: Router
  ) { }

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    try {
      const url = state.url as EInspectionStepUrl
      await this.setCurrentIndex(url);
      // this.stepManagerService.setCurrentPhotoIdx(0);
      await this.setTimer();
      const steps = this.stepManagerService.getSteps();
      await this.DB.addProgress(steps);
      this.progressService.setCurrentProgress(steps);
    }
    catch (error) {
      console.log("Error al fetchear")
      this.router.navigate(['/']);
    }
  }

  async setCurrentIndex(url: EInspectionStepUrl): Promise<void> {
    const idx = await this.stepManagerService.getIdxFromUrl(url);
    this.stepManagerService.setCurrentStepIdx(idx);
  }

  async setTimer(): Promise<void> {
    await this.inspectionControlService.initInspectionTimer();
  }

}
