<button
  [ngClass]="buttonClasses"
  [disabled]="disabled"
>
  <div *ngIf="count != null" class="count-number">
    <span>{{count}}</span>
  </div>
  <ion-icon
    [name]="icon"
  ></ion-icon>
  <span class="btn-text">{{text}}</span>
</button>