import { Injectable } from '@angular/core';
import {
  IInspectionOrderSteps,
} from 'src/app/interfaces/Outputs';
import {
  EInspectionStep,
  EInspectionStepUrl,
} from 'src/app/interfaces/PWA/Inspection';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { IFeature } from 'src/app/interfaces/Outputs/Feature';
import {
  IAccesory,
} from 'src/app/interfaces/Outputs/Accesory';
import { FormSectionDataAndPhotos, FormElements } from 'src/app/interfaces/PWA/Forms';
import { InspectionApiService } from '../InspectionApi/inspection-api.service';
import { SectionStepManagerService } from '../SectionStepManager/section-step-manager.service';
import { AppDB } from 'src/app/config/idb';
import { PhotoDisplay } from 'src/app/interfaces/PWA/Files';
import { FileService } from '../../General/File/file.service';

@Injectable({
  providedIn: 'root',
})
export class StepManagerService {
  public currentStepIdx$ = new BehaviorSubject<number | null>(null);
  public currentDynamicStepIdx$ = new BehaviorSubject<number | null>(null);

  constructor(
    private readonly router: Router,
    private readonly inspectionApiService: InspectionApiService,
    private readonly sectionStepManagerService: SectionStepManagerService,
    private readonly db: AppDB,
    private readonly fileService: FileService,
  ) { 
  }

  public saveSteps(steps: IInspectionOrderSteps[]) {
    // TODO: Filtramos el step de daños del flujo actual, ya que no aplica
    localStorage.setItem('steps', JSON.stringify(steps));
  }

  public getSteps(): IInspectionOrderSteps[] {
    const steps = localStorage.getItem('steps');
    if (!steps) {
      throw new Error('Steps not found');
    }
    return JSON.parse(steps);
  }

  public navigateToStep(stepIndex: number) {
    const stepId = this.getId(stepIndex);
    const url = this.getUrlFromStepId(stepId);
    this.setCurrentStepIdx(stepIndex);
    this.router.navigate([url]);
  }

  public navigateToFormSection(sectionId: number) {
    this.navigateToStepUrl(EInspectionStepUrl.Forms);
    this.sectionStepManagerService.setCurrentSectionIdx(sectionId);
  }
  
  public navigateToStepUrl(url: EInspectionStepUrl) {
    const stepId = this.getStepIdFromUrl(url);
    const idx = this.getIdx(stepId);
    this.navigateToStep(idx);
  }

  public moveStep(to: 'next' | 'prev') {
    const currentStepIndex = this.currentStepIdx$.value!;
    const nextStepIndex =
      to === 'next' ? currentStepIndex + 1 : currentStepIndex - 1;
    if (nextStepIndex < 0) {
      return
    }
    this.navigateToStep(nextStepIndex);
  }

  public getId(idx: number): EInspectionStep {
    const stepOrder = this.getSteps();
    return stepOrder[idx].id;
  }

  public getIdx(id: EInspectionStep): number {
    const stepOrder = this.getSteps();
    return stepOrder.findIndex((step) => step.id === id);
  }

  public async getIdxFromUrl(url: EInspectionStepUrl): Promise<number> {
    const steps = this.getSteps();
    const stepId = this.getStepIdFromUrl(url);
    return steps.findIndex((step) => step.id === stepId);
  }

  public getUrlFromStepId(stepId: EInspectionStep): EInspectionStepUrl {
    const stepMap: Record<EInspectionStep, EInspectionStepUrl> = {
      [EInspectionStep.Feature]: EInspectionStepUrl.Feature,
      [EInspectionStep.Accesory]: EInspectionStepUrl.Accesory,
      [EInspectionStep.Forms]: EInspectionStepUrl.Forms,
      [EInspectionStep.Photo]: EInspectionStepUrl.Photo,
      [EInspectionStep.Finish]: EInspectionStepUrl.Finish,
      [EInspectionStep.Evaluation]: EInspectionStepUrl.Evaluation,
    };
    return stepMap[stepId];
  }

  public getStepIdFromUrl(url: EInspectionStepUrl): EInspectionStep {
    const stepMap: Record<EInspectionStepUrl, EInspectionStep> = {
      [EInspectionStepUrl.Feature]: EInspectionStep.Feature,
      [EInspectionStepUrl.Accesory]: EInspectionStep.Accesory,
      [EInspectionStepUrl.Forms]: EInspectionStep.Forms,
      [EInspectionStepUrl.Photo]: EInspectionStep.Photo,
      [EInspectionStepUrl.Finish]: EInspectionStep.Finish,
      [EInspectionStepUrl.Evaluation]: EInspectionStep.Evaluation,
    };
    return stepMap[url];
  }

  public setCurrentStepIdx(idx: number) {
    this.currentStepIdx$.next(idx);
  }


  // public isLastStep(stepIdx: number): boolean {
  //   const steps = this.getSteps();
  //   return stepIdx === steps.length - 1;
  // }


  public async getStepLength(screenData: any, stepId: EInspectionStep): Promise<number> {
     if (stepId === EInspectionStep.Feature) {
      return (screenData as IFeature).form_elements.length;
    } else if (stepId === EInspectionStep.Accesory) {
      return (screenData as IAccesory).form_elements.length;
    } else if (stepId === EInspectionStep.Photo) {
      return parseInt(localStorage.getItem('total_photos') ?? '0', 10);
      // return (screenData as IPhoto).required_photos.length;
    } else if (stepId === EInspectionStep.Forms) {
      const dynamicSections = this.sectionStepManagerService.getSectionSteps();
      return dynamicSections.length;
    } else if (stepId === EInspectionStep.Finish) {
      return 1;
    } else if (stepId === EInspectionStep.Evaluation) {
      return 1;
    }
    return 0;
  }

  public async getStepAnswers(stepId: EInspectionStep): Promise<FormSectionDataAndPhotos[] | PhotoDisplay[]> {
    if (stepId === EInspectionStep.Forms) {
      return await this.getFormAnswers();
    } else if (stepId === EInspectionStep.Photo) {
      return await this.getPhotoAnswers();
    }
    return [];
  }


  public async getFormAnswers(): Promise<FormSectionDataAndPhotos[]> {
    const formSections = await this.db.getAllFormSections();    
    return await Promise.all(formSections.map(async section => {
      const formElements: FormElements = {};
      for (const [elementId, response] of Object.entries(section.formElements)) {
        const numericElementId = Number(elementId);
        const formResponseElementPhoto = await this.db.getFormElementPhoto(numericElementId);
        formElements[numericElementId] = {
          label: response.label,
          value: response.value,
          isPhoto: response.isPhoto,
          photos: formResponseElementPhoto?.photos.map(blob => URL.createObjectURL(blob)) ?? []
        };
      }
      return {
        id: section.id,
        formId: section.formId,
        title: section.sectionName,
        completed: section.completed,
        formElements
      } as FormSectionDataAndPhotos;
    }));
  }

  public async getPhotoAnswers(): Promise<PhotoDisplay[]> {
    const photoSteps = await this.db.getAllFiles();
    return photoSteps.map(photoStep => ({
      title: photoStep.photoName,
      photoId: photoStep.photoId,
      files: photoStep.files.map(file => ({
        data: URL.createObjectURL(file.data),
        ext: file.ext
      }))
    }));
  }

  public navigateToPhotoId(photoId: number) {
    const steps = this.getSteps();
    const photoStepIndex = steps.findIndex(step => step.id === EInspectionStep.Photo);
    if (photoStepIndex !== -1) {
      this.setCurrentStepIdx(photoStepIndex);
      this.router.navigate([this.getUrlFromStepId(EInspectionStep.Photo)], { queryParams: { photoId } });
    }
  }
}
