import { Injectable } from '@angular/core';
import { EExtension, Extension, FileDetail, Media } from 'src/app/interfaces/PWA/Files';
import {
  CameraCapabilities,
  CameraCapabilitiesStream,
  MediaCapabilitiesRank,
  REQUIRED_CAPABILITIES_DIMS,
} from 'src/app/interfaces/PWA/CameraCapabilities';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  CAMERA_STORAGE_KEY = 'camera_pwa';
  
  constructor(
    private readonly platform: Platform
  ) {}

  private get videoConstraints(): MediaTrackConstraints {
    return {
      width: { ideal: REQUIRED_CAPABILITIES_DIMS.WIDTH },
      height: { ideal: REQUIRED_CAPABILITIES_DIMS.HEIGHT },
      facingMode: { exact: 'environment' },
    };
  }

  private get baseCapabilities(): MediaCapabilitiesRank {
    return {
      zoom: false,
      torch: false,
      facingMode: false,
      width: 0,
      height: 0,
    };
  }

  private async createStream(deviceId?: string): Promise<MediaStream> {
    let constraints: MediaStreamConstraints = {};
    if (deviceId) {
      constraints = {
        video: {
          deviceId: deviceId,
          width: { ideal: REQUIRED_CAPABILITIES_DIMS.WIDTH },
          height: { ideal: REQUIRED_CAPABILITIES_DIMS.HEIGHT },
        },
      };
    } else {
      constraints = {
        video: {
          width: { ideal: REQUIRED_CAPABILITIES_DIMS.WIDTH },
          height: { ideal: REQUIRED_CAPABILITIES_DIMS.HEIGHT },
          facingMode: 'environment',
        },
      };
    }
    return navigator.mediaDevices.getUserMedia(constraints);
  }

  private getSavedCamera(): CameraCapabilities | null {
    return JSON.parse(localStorage.getItem(this.CAMERA_STORAGE_KEY) ?? 'null');
  }

  private saveCamera(camera: Omit<CameraCapabilities, 'stream'>) {
    localStorage.setItem(this.CAMERA_STORAGE_KEY, JSON.stringify(camera));
  }

  private async getCameraDevices(
    devices: MediaDeviceInfo[]
  ): Promise<MediaDeviceInfo[]> {
    return devices.filter((device) => device.kind === 'videoinput');
  }

  private async isCameraValid(deviceId: string): Promise<boolean> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some(
      (device) => device.deviceId === deviceId && device.kind === 'videoinput'
    );
  }

  public stopStream(stream: MediaStream): void {
    stream.getTracks().forEach(track => track.stop());
  }

  private rankCapabilities(
    capabilities: MediaTrackCapabilities
  ): MediaCapabilitiesRank {
    return {
      zoom: 'zoom' in capabilities,
      torch: 'torch' in capabilities,
      facingMode: capabilities.facingMode?.includes('environment') ?? false,
      width: capabilities.width?.max ?? 0,
      height: capabilities.height?.max ?? 0,
    };
  }

  private async returnCameraStream(
    camera: CameraCapabilities
  ): Promise<CameraCapabilitiesStream> {
    const stream = await this.createStream(camera.deviceId);
    return { ...camera, stream };
  }

  public async returnDefaultCameraStream(): Promise<CameraCapabilitiesStream> {
    const stream = await this.createStream();
    return {
      deviceId: 'default',
      stream: stream,
      capabilities: {
        zoom: false,
        torch: false,
        facingMode: true,
        width: REQUIRED_CAPABILITIES_DIMS.WIDTH,
        height: REQUIRED_CAPABILITIES_DIMS.HEIGHT,
      },
    };
  }

  public async getBestCameraStream(): Promise<CameraCapabilitiesStream> {
    try {
      const savedCamera = this.getSavedCamera();
      if (savedCamera && (await this.isCameraValid(savedCamera.deviceId))) {
        return await this.returnCameraStream(savedCamera);
      }
      const bestCamera = await this.findBestCamera();
      if (bestCamera) {
        this.saveCamera({
          deviceId: bestCamera.deviceId,
          capabilities: bestCamera.capabilities,
        });
        return await this.returnCameraStream(bestCamera);
      }
      return await this.returnDefaultCameraStream();
    } catch (error) {
      console.error('Error getting camera stream', error);
      return await this.returnDefaultCameraStream();
    }
  }

  public async findBestCamera(): Promise<CameraCapabilities | null> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameraDevices = await this.getCameraDevices(devices);
    let bestCamera: MediaDeviceInfo | null = null;
    let bestCapabilities = this.baseCapabilities;
    for (const device of cameraDevices) {
      const capabilities = await this.getCameraCapabilities(device.deviceId);
      const rankedCapabilities = this.rankCapabilities(capabilities);

      if (this.compareCapabilities(bestCapabilities, rankedCapabilities)) {
        bestCamera = device;
        bestCapabilities = rankedCapabilities;
      }
    }
    return bestCamera
      ? {
          deviceId: bestCamera.deviceId,
          capabilities: bestCapabilities,
        }
      : null;
  }

  private async getCameraCapabilities(
    deviceId: string
  ): Promise<MediaTrackCapabilities> {
    const stream = await this.createStream(deviceId);
    const videoTrack = stream.getVideoTracks()[0];
    const capabilities = videoTrack.getCapabilities();
    this.stopStream(stream);
    videoTrack.stop();
    return capabilities;
  }

  private compareCapabilities(
    current: MediaCapabilitiesRank,
    candidate: MediaCapabilitiesRank
  ): boolean {
    const currentScore = Object.values(current).filter(Boolean).length;
    const candidateScore = Object.values(candidate).filter(Boolean).length;
    return candidateScore > currentScore;
  }

  public async toggleZoom(stream: MediaStream, zoom: number): Promise<void> {
    const videoTrack = (await this.getVideoTrack(stream)) as any;
    videoTrack.applyConstraints({ advanced: [{ zoom }] });
  }

  public async toggleTorch(stream: MediaStream, torch: boolean): Promise<void> {
    const videoTrack = (await this.getVideoTrack(stream)) as any;
    videoTrack.applyConstraints({ advanced: [{ torch }] });
  }

  private async getVideoTrack(stream: MediaStream): Promise<MediaStreamTrack> {
    return stream.getVideoTracks()[0];
  }

  public checkFileDetail(file: File, accept: Extension[]): FileDetail {
    const ext = this.getFileExt(file);
    if (!accept.includes(ext)) {
      // return `El archivo debe tener alguna de las siguientes extensiones: ${accept.join(', ')}`;
      throw new Error(
        `El archivo debe tener alguna de las siguientes extensiones: ${accept.join(
          ', '
        )}`
      );
    }
    const fileDetail = {
      data: file,
      ext: ext,
    } as FileDetail;
    return fileDetail;
  }

  public takePhoto(video: HTMLVideoElement): string {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    if (!context) {
      throw new Error('No se pudo obtener el contexto del canvas');
    }
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    return dataUrl;
  }

  public async initRecorder({
    stream,
  }: {
    stream: MediaStream;
  }): Promise<MediaRecorder> {
    const options = { mimeType: 'video/webm;codecs=vp9' };
    const mediaRecorder = new MediaRecorder(stream, options);
    return mediaRecorder;
  }

  public async onStopRecorder(chunks: Blob[]) {
    const videoBuffer = new Blob(chunks, { type: 'video/mp4' });
    return videoBuffer;
  }

  public async stopRecorder(mediaRecorder: MediaRecorder) {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  }

  public async b64toBlob(b64Data: string): Promise<Blob> {
    const response = await fetch(b64Data);
    console.log(response);
    const blob = await response.blob();
    return blob;
  }

  public async getBlobUrl(blob: Blob): Promise<string> {
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }

  public revokeBlobUrl(blobUrl: string) {
    URL.revokeObjectURL(blobUrl);
  }

  public getFileExt(file: File): Extension {
    return file.name.split('.').pop() as Extension;
  }

  public downloadFile(file: FileDetail, fileName: string) {
    if (file.ext !== EExtension.pdf) {
      throw new Error('El archivo no es un PDF');
    }
    const url = URL.createObjectURL(file.data);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }

  public async getFrontCameraStream(): Promise<CameraCapabilitiesStream> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const frontCamera = devices.find(
      (device) => device.kind === 'videoinput' && device.label.toLowerCase().includes('front')
    );
    if (!frontCamera) {
      console.warn('No front camera found, using default camera');
      return this.returnDefaultCameraStream();
    }
    const stream = await this.createStream(frontCamera.deviceId);
    return {
      deviceId: frontCamera.deviceId,
      stream: stream,
      capabilities: {
        zoom: false,
        torch: false,
        facingMode: true,
        width: REQUIRED_CAPABILITIES_DIMS.WIDTH,
        height: REQUIRED_CAPABILITIES_DIMS.HEIGHT,
      },
    };
  }

  public async hasFrontCamera(): Promise<boolean> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some(
      (device) => device.kind === 'videoinput' && device.label.toLowerCase().includes('front')
    );
  }
}
