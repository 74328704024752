import { Injectable } from '@angular/core';
import { FormElementBuilderService } from '../FormElementBuilder/form-element-builder.service';
import { IDynamicFormElement } from 'src/app/interfaces/Outputs/DynamicFormElement';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { FormUserData } from 'src/app/interfaces/PWA/Forms';
import { IDynamicFormHiddenElement } from 'src/app/interfaces/Outputs/DynamicFormHiddenElement';
@Injectable({
  providedIn: 'root',
})
export class FormControlService {

  constructor(
    private readonly builder: FormElementBuilderService,
  ) {}

  modelToFormGroup(
    fields: IDynamicFormElement[],
    cachedData?: FormUserData
  ): FormGroup {
    const group: { [key: string]: FormControl | FormGroup } = {};
    fields
    .forEach((field) => {
      const control = this.builder
        .setField(field)
        .build(cachedData?.[field.id_elemento]?.value);
      group[field.id_elemento] = control;
    });

    const form = new FormGroup(group);
    console.log(form);

    this.addDependencyListeners(form, fields);

    return form;
  }

  addDependencyListeners(
    form: FormGroup,
    fields: IDynamicFormElement[],
  ): void {
    fields.forEach((field) => {
      if (field.dependency) {
        const dependentFieldId = field.dependency.id_elemento_dependiente.toString();
        const dependentFieldControl  = form.get(dependentFieldId);
        const currentFieldControl = form.get(field.id_elemento.toString());

        if (dependentFieldControl && currentFieldControl) {
          const dependencyValues = field.dependency.valor_dependencia.split(';');
          dependentFieldControl.valueChanges.subscribe((value) => {
            this.checkDependency(value, dependencyValues, currentFieldControl);
          });
          this.checkDependency(dependentFieldControl.value, dependencyValues, currentFieldControl);
        }
      }
    });
  }  

  private checkDependency(
    value: string,
    dependencyValues: string[],
    fieldControl: AbstractControl
  ): void {
    if (dependencyValues.includes(value)) {
      fieldControl.enable();
    } else {
      fieldControl.disable();
    }
  }

  filterFields(
    fields: IDynamicFormElement[],
  ): IDynamicFormElement[] {
    const hiddenElements = this.getHiddenFieldsFromStorage();
    return fields.filter((field) => {
      return !hiddenElements.some((hidden) => hidden.id_elemento === field.id_elemento);
    });
  }

  setHiddenFieldsToStorage(
    hiddenElements: IDynamicFormHiddenElement[]
  ): void {
    localStorage.setItem('hiddenElements', JSON.stringify(hiddenElements));
  }

  getHiddenFieldsFromStorage(): IDynamicFormHiddenElement[] {
    return JSON.parse(localStorage.getItem('hiddenElements') ?? '[]');
  }



}

