
export enum EInspectionStep {
  // Deprecadas
  // CarSelection = 0,
  Feature = 1,
  Accesory = 2,
  Forms = 7,
  Photo = 3,
  Finish = 5,
  Evaluation = 6,
}

export enum EInspectionStepUrl {
  // Deprecadas
  // CarSelection = '/inspection/car-selection',
  Feature = '/inspection/feature',
  Accesory = '/inspection/accesory',
  Forms =  '/inspection/formularios',
  Photo = '/inspection/photo',
  Finish = '/inspection/finish',
  Evaluation = '/inspection/evaluation',
}

