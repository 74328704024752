import { IDynamicFormDependency } from "./DynamicFormDependency";
import { IDynamicFormValue } from "./DynamicFormValue";

export enum EDynamicFormElementType {
    IMAGEN = 'IMAGEN',
    TEXT = 'TEXT',
    TEXT_AÑO = 'TEXT-AÑO',
    TEXT_CHASIS = 'TEXT-CHASIS',
    TEXT_PATENTE = 'TEXT-PATENTE',
    TEXT_RUT = 'TEXT-RUT',
    TEXT_FONO = 'TEXT-FONO',
    TEXT_EMAIL = 'TEXT-EMAIL',
    SELECT = 'SELECT',
    COMBO = 'COMBO',
}


export interface IDynamicFormElement {
    id_elemento: number;
    nombre_elemento: string;
    id_seccion: number;
    tipo_elemento: EDynamicFormElementType;
    cantidad_fotos: number;
    requerido: boolean;
    mensaje_error: string;
    orden: number | null;
    habil: boolean;
    values: IDynamicFormValue[];
    dependency: IDynamicFormDependency | null;
}