import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { StylesService } from 'src/app/services/UIHandlers/Styles/styles.service';

@Injectable({
  providedIn: 'root',
})
export class AppThemeResolverService implements Resolve<any> {
  constructor(
    private readonly stylesService: StylesService,
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    await this.stylesService.setAppTheme();
  }

}
// TODO: Implementar para las paginas de inspección
