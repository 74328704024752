import { Component, Input, OnInit } from '@angular/core';
import { ButtonType } from 'src/app/interfaces/PWA/UI';

@Component({
  selector: 'app-box-button',
  templateUrl: './box-button.component.html',
  styleUrls: ['./box-button.component.scss'],
})
export class BoxButtonComponent  implements OnInit {

  @Input() text: string = '';
  @Input() icon: string = '';
  @Input() type: ButtonType = 'primary';
  @Input() disabled: boolean = false;
  @Input() count: number | null = null;
  
  buttonClasses = [
    'box-button',
  ]

  constructor() { }

  ngOnInit() {
    this.buttonClasses.push(`btn-${this.type}`);
  }

}
