import { Platform } from '@ionic/angular';
import { EventEmitter, Injectable } from '@angular/core';
import { DISTANCE_FROM_TARGET, JoyrideBackdropService, JoyrideService } from 'ngx-joyride';
import { JoyrideOptions } from 'ngx-joyride/lib/models/joyride-options.class';
import { IInspectionOrder } from 'src/app/interfaces/Outputs';
import {
  EInspectionStep,
  EInspectionStepUrl,
} from 'src/app/interfaces/PWA/Inspection';
import { ITourStep } from 'src/app/interfaces/PWA/Tour';
import { StepManagerService } from '../StepManager/step-manager.service';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  withLatestFrom,
} from 'rxjs';
import { OrientationService } from '../../General/Orientation/orientation.service';
import { RouteSharedDataService } from '../../Workarounds/RouteSharedData/route-shared-data.service';
import { PermissionsService } from '../../General/Permissions/permissions.service';
import { JoyrideStepInfo } from 'ngx-joyride/lib/models/joyride-step-info.class';

@Injectable({
  providedIn: 'root',
})
export class TourManagerService {
  // tourCanStart$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public tooltipsReady$: EventEmitter<boolean> = new EventEmitter<boolean>();
  // public forceTour$: EventEmitter<void> = new EventEmitter<void>();
  tourPaused = false;
  forcedTour = false;

  constructor(
    private readonly joyrideService: JoyrideService,
    private readonly stepManagerService: StepManagerService,
    private readonly permissionsService: PermissionsService,
    private readonly routeSharedDataService: RouteSharedDataService,
    private readonly orientationService: OrientationService,
    private readonly platform: Platform,
  ) {


    combineLatest([
      this.tooltipsReady$,
      this.orientationService.modalActive$.pipe(distinctUntilChanged()),
      this.permissionsService.permissionsModalOpen$.pipe(
        distinctUntilChanged()
      ),
    ])
      .pipe(
        map(([tooltipsReady, modalActive, permissionModalOpen]) => {
          return tooltipsReady && !modalActive && !permissionModalOpen;
        }),
      )
      .subscribe((cantStart) => {
        if (!cantStart) {
          this.pauseTour();
        } else {
          this.tourPaused = false;
          const stepId = this.stepManagerService.getId(this.stepManagerService.currentStepIdx$.value!);
            if (!platform.is('ios')) {
              this.initStepTour(stepId);
            }
        }
      });
  }

  saveAppTooltipsTargets(inspectionOrder: IInspectionOrder) {
    const tooltips = inspectionOrder.tooltips;
    localStorage.setItem('tooltips', JSON.stringify(tooltips));
  }

  getAppTooltips(): ITourStep[] {
    const tooltips = localStorage.getItem('tooltips');
    if (!tooltips) return [];
    return JSON.parse(tooltips);
  }

  getStepTooltips(stepId: EInspectionStep): ITourStep[] {
    const tooltips = this.getAppTooltips();
    let targets = [] as string[];
    if (stepId === EInspectionStep.Forms) {
      targets = this.getFormPageTargets();
    }
    else if (stepId === EInspectionStep.Photo) {
      targets = this.getPhotoPageTargets();
    }
    return tooltips.filter((tooltip) => targets.includes(tooltip.Target));
  }


  findTourStep(name: string): ITourStep | undefined {
    const tooltips = this.getAppTooltips();
    return tooltips.find((tooltip) => tooltip.Target === name);
  }


  pauseTour() {
    this.tourPaused = true;
    this.joyrideService.closeTour();
  }


  getNotVisitedTooltips(tooltips: ITourStep[]): ITourStep[] {
    const visitedTargets = this.getVisitedTargets();
    const notVisitedTooltips = tooltips.filter(
      (tooltip) => !visitedTargets.includes(tooltip.Id)
    );
    return notVisitedTooltips;
  }

  // // getCameraTooltips(): ITourStep[] {
  // //   const tooltips = this.getAppTooltips();
  // //   const visitedTargets = this.getVisitedTargets();
  // //   const cameraTargets = this.getCameraTargets();
  // //   const notVisitedTooltips = tooltips.filter(
  // //     (tooltip) => !visitedTargets.includes(tooltip.Id)
  // //   );
  // //   const cameraToolTips = notVisitedTooltips
  // //     .filter((tooltip) => cameraTargets.includes(tooltip.Target))
  // //     .filter(
  // //       (tooltip, index, self) =>
  // //         index === self.findIndex((t) => t.Target === tooltip.Target)
  // //     );

  // //   return cameraToolTips;
  // // }

  public initStepTour(stepId: EInspectionStep): void {
    const tooltips = this.getStepTooltips(stepId);
    this.initTour(tooltips);
  }

  public initTour(tour: ITourStep[]): void {

    const tooltips = this.forcedTour ? tour : this.getNotVisitedTooltips(tour);

    const joyrideTour = this.startJoyrideTour(tooltips);
    let previousStep = "";
    const joyrideTourSubscription = joyrideTour.subscribe({
      next: (stepInfo) => {
        // find the step that is being shown because the [joyride-step] property is the stepInfo.name
        if (previousStep) {
          const previousTarget = document.querySelector(`[joyridestep="${previousStep}"]`);
          previousTarget?.removeAttribute('style');
        }

        const target = document.querySelector(`[joyridestep="${stepInfo.name}"]`);
        target?.setAttribute('style', 'padding: 4px');
        previousStep = stepInfo.name;
        // setTimeout(() => {
        //   const backdropTop = document.querySelector('.backdrop-top') as HTMLElement;
        //   const backdropBottom = document.querySelector('.backdrop-bottom') as HTMLElement;
        //   const largestBackdrop = backdropTop?.clientHeight! > backdropBottom?.clientHeight! ? backdropTop : backdropBottom;
        //   const stepBody = document.querySelector('.joyride-step__container') as HTMLElement;
        //   if (target) {
        //     const targetRect = target.getBoundingClientRect();
        //     const targetBottom = targetRect.bottom;
        //     const targetLeft = targetRect.width > 200 ? targetRect.left + 80 : targetRect.left;
        //     const curve = targetRect.width > 200 ? 0 : 100;
        //     const targetTop = targetRect.top;
        //     const targetWidth = targetRect.width;
        //     const targetHeight = targetRect.height;
        //     const stepBodyRect = stepBody.getBoundingClientRect();
        //     const stepBodyHeight = stepBodyRect.height;
        //     const stepBodyWidth = stepBodyRect.width;
        //     const stepBodyTop = stepBodyRect.top;
        //     const stepBodyLeft = stepBodyRect.left;
        //     const stepBodyBottom = stepBodyRect.bottom;
        //     const stepBodyRight = stepBodyRect.right;

        //     if (largestBackdrop === backdropBottom) {
        //       largestBackdrop!.innerHTML = `
        //       <svg viewBox="0 0 390 390" class="dotted-line">
        //       <path d="M${stepBodyLeft*0.9},200 C${curve},100 100, 0 ${targetLeft}, 4"/>
        //       </svg>`;
        //     }else{
        //       largestBackdrop!.innerHTML = `
        //       <svg viewBox="0 0 390 ${targetTop}" class="dotted-line">
        //       <path d="M${stepBodyLeft*0.9},${stepBodyBottom-stepBodyHeight/2} C${curve},${stepBodyBottom+100} 100, ${targetTop-200} ${targetLeft}, ${targetTop-90}"/>
        //       </svg>`;
        //     }
        //   }
        //   //d="M200,100 C100,100 50, 20 200, 2"/>
        // }, 1);
      },
      complete: () => {
        if (previousStep) {
          const previousTarget = document.querySelector(`[joyridestep="${previousStep}"]`);
          previousTarget?.removeAttribute('style');
        }
        console.log('TOUR COMPLETED');
        if (!this.tourPaused) {
          this.markVisitedTargets(tooltips);
        }
        this.forcedTour = false;
        joyrideTourSubscription.unsubscribe();
      },
    });
  }

  public forceTour() {
    this.forcedTour = true;
    this.tooltipsReady$.emit(true);
  }


  private startJoyrideTour(tooltips: ITourStep[]): Observable<JoyrideStepInfo> {
    const targets = this.getTourTargets(tooltips);
    const options = this.getTourOptions();
    const joyrideTour = this.joyrideService.startTour({
      steps: targets,
      ...options,
    });
    return joyrideTour;
  }

  private getTourTargets(tourSteps: ITourStep[]): string[] {
    return tourSteps.map((step) => {
      return step.Target;
    });
  }

  private getTourOptions(): Omit<JoyrideOptions, 'steps'> {
    return {
      themeColor: 'white',
      customTexts: {
        next: 'Siguiente >',
        prev: '< Anterior',
        done: 'Ok',
        // close: 'Cerrar'
      },
    };
  }

  getVisitedTargets(): number[] {
    const visitedTargets = localStorage.getItem('visitedTargets');
    if (!visitedTargets) return [];
    return JSON.parse(visitedTargets);
  }

  markVisitedTargets(tour: ITourStep[]) {
    const targetIds = tour.map((step) => step.Id);
    const visitedTargets = this.getVisitedTargets();
    const newTargetIds = targetIds.filter(id => !visitedTargets.includes(id));
    const newVisitedTargets = visitedTargets.concat(newTargetIds);
    localStorage.setItem('visitedTargets', JSON.stringify(newVisitedTargets));
  }

  getFormPageTargets(): string[] {
    return [
      'progress-bar',
      'timer',
      'help-desk',
      'form',
      'next-button',
      'prev-button',
    ];
  }

  getPhotoPageTargets(): string[] {
    return [
      'open-camera-button',
      'upload-file-button',
      'gallery',
    ]
  }

}
