import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { IconButtonModule } from "../../icon-button/icon-button.module";
import { FullScreenMediaModalComponent } from "./full-screen-media-modal.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    IconButtonModule    
  ],
  declarations: [FullScreenMediaModalComponent],
  exports: [FullScreenMediaModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FullScreenMediaModalModule { }