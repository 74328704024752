import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { IHelpDesk } from 'src/app/interfaces/BFF';
import { EInspectionStep } from 'src/app/interfaces/PWA/Inspection';
import { ITourStep } from 'src/app/interfaces/PWA/Tour';
import { OrientationService } from '../../General/Orientation/orientation.service';
import { PermissionsService } from '../../General/Permissions/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class RouteSharedDataService {

  readonly ableToShowAppModalSubject$: BehaviorSubject<boolean>;
  readonly helpDeskDataSubject = new BehaviorSubject<Partial<IHelpDesk> | null>(null);
  helpDeskData$ = this.helpDeskDataSubject.asObservable();
  readonly tooltipsDataSubject = new BehaviorSubject<ITourStep[] | null>(null);
  tooltipsData$ = this.tooltipsDataSubject.asObservable();

  loadingDataSubject = new BehaviorSubject<boolean>(false);
  loadingData$ = this.loadingDataSubject.asObservable();

  constructor(
    private readonly orientationService: OrientationService,
    private readonly permissionsService: PermissionsService,
  ) { 

    this.ableToShowAppModalSubject$ = new BehaviorSubject<boolean>(false);

    combineLatest([
      this.orientationService.lockIsReady$,
      this.permissionsService.permissionsModalOpen$
    ])
    .pipe(
      map(([lockIsReady, permissionsModalOpen]) => lockIsReady && !permissionsModalOpen) // Define the condition
    )
    .subscribe(this.ableToShowAppModalSubject$);

  }
  
  setLoadingData(loading: boolean) {
    this.loadingDataSubject.next(loading);
  }

  setHelpDeskData(helpDesk?: IHelpDesk) {
    const helpDeskData = helpDesk || this.getGenericHelpDeskData();
    this.helpDeskDataSubject.next(helpDeskData);
  }


  getGenericHelpDeskData(): Partial<IHelpDesk> {
    return {
      title_help: '¿Necesitas ayuda?',
      description_help: 'Si tienes alguna duda, puedes contactar a soporte técnico.',
    }
  }


}
