import { Component, Input, OnInit } from '@angular/core';
import { ButtonType, Size } from 'src/app/interfaces/PWA/UI';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent  implements OnInit {

  @Input() icon: string = '';
  @Input() type: ButtonType = 'primary';
  @Input() size: Size = 'md';
  @Input() disabled: boolean = false;
  buttonClasses = [
    'icon-button',
  ]

  constructor() { }

  ngOnInit() {
    this.buttonClasses.push(this.size)
    this.buttonClasses.push(`btn-${this.type}`);
  }

}
