import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { ITheme } from 'src/app/interfaces/BFF';
import { IPayloadToken } from 'src/app/interfaces/Global/PayloadToken';
import { IInspectionOrder } from 'src/app/interfaces/Outputs';
import { ISummary, ISummaryScreen } from 'src/app/interfaces/Outputs/Summary';

@Injectable({
  providedIn: 'root'
})
export class StylesService {

  constructor() { }

  // TODO: Crear un método para los estilos devueltos por cada pantalla

  async saveAppTheme(inspectionOrder: IInspectionOrder){
    const theme = inspectionOrder.theme;
    const oi = inspectionOrder.id;
    let primary_color = theme.primary_color;
    // TODO: Provisional, esto es para demostrar el cambio de colores en la app
    if (oi === 769230) {
      primary_color = '#0b3699';
    } else if (oi === 973662) {
      primary_color = '#0c8c51'; 
    } else if (oi === 803171) {
      primary_color = '#d0201b' 
    }
    const newTheme = {
      ...theme,
      primary_color
    }
    localStorage.setItem('theme', JSON.stringify(newTheme));
  }

  async getAppTheme(): Promise<ITheme | null> {
    const theme = localStorage.getItem('theme');
    if (!theme) return null;
    return JSON.parse(theme);
  }

  async setAppTheme(){
    const theme = await this.getAppTheme()
    if (!theme) return;
    document.documentElement.style.setProperty('--primary-color', theme.primary_color);
    document.documentElement.style.setProperty('--title-font-color', theme.title_font_color);
    document.documentElement.style.setProperty('--description-font-color', theme.description_font_color);
    document.documentElement.style.setProperty('--background-screen', theme.backgroundScreen);
    document.documentElement.style.setProperty('--background-card', theme.backgroundCard);
  }
}
