import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { IPayloadToken } from 'src/app/interfaces/Global/PayloadToken';
import { getCurrentUTCTimestamp } from 'src/app/utils/Date';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  public remainingTime$ = new BehaviorSubject<number>(0);

  constructor() { }

  public setTimer(time: number, onExpired: () => void): void {
    this.remainingTime$.next(time);
    const interval = setInterval(() => {
      const remainingTime = this.remainingTime$.value;
      if (remainingTime > 0) {
        this.remainingTime$.next(remainingTime - 1000);
      }
      else {
        onExpired();
        clearInterval(interval);
      }
    }, 1000);
  }

  public getRemainingTimeFromToken(token: string): number {
    const payload = jwtDecode<IPayloadToken>(token);
    const endDate = parseInt(payload.end_date);
    const now = getCurrentUTCTimestamp();
    return Math.max(0, endDate - now);
  }
}
