import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  OrientationModalComponent,
  OrientationService
} from "./orientation.service";
import { IonicModule } from "@ionic/angular";
import { AppButtonModule } from "src/app/components/ui/app-button/app-button.module";

@NgModule({
  imports: [CommonModule, IonicModule, AppButtonModule],
  declarations: [OrientationModalComponent],
  providers: [OrientationService]
})
export class OrientationModule {}