import { Injectable } from '@angular/core';
import {
  Observable,
} from 'rxjs';
import {
  IInspectionOrder,
} from 'src/app/interfaces/Outputs';
import { Router } from '@angular/router';
import {
  EInspectionStep,
} from 'src/app/interfaces/PWA/Inspection';
import { ITourStep } from 'src/app/interfaces/PWA/Tour';
import { TimerService } from '../Timer/timer.service';
import { StepManagerService } from '../StepManager/step-manager.service';
import { InspectionApiService } from '../InspectionApi/inspection-api.service';
import { AlertType } from 'src/app/interfaces/Global/Alert';
import { jwtDecode } from 'jwt-decode';
import { IPayloadToken } from 'src/app/interfaces/Global/PayloadToken';
import { FileService } from '../../General/File/file.service';
import { TourManagerService } from '../TourManager/tour-manager.service';
import { PopupType } from 'src/app/interfaces/PWA/Photo';
import { SectionStepManagerService } from '../SectionStepManager/section-step-manager.service';
import { AppDB } from 'src/app/config/idb';

// Utilizar este servicio para mantener persistencia de datos
@Injectable({
  providedIn: 'root',
})
export class InspectionControlService {
  // Steps
  
  public tourChange$!: Observable<ITourStep[]>;

  constructor(
    private readonly inspectionApiService: InspectionApiService,
    private readonly stepManagerService: StepManagerService,
    private readonly sectionStepManagerService: SectionStepManagerService,
    private readonly timerService: TimerService,
    private readonly router: Router,
    // private readonly cacheService: CacheService,
    private readonly DB: AppDB,
    private readonly tourManagerService: TourManagerService,
    private readonly fileService: FileService
  ) {
  }

  public async startInspection(orderData: IInspectionOrder): Promise<void> {
    try {
      const { jwt } = await this.inspectionApiService.startInspectionOrder(orderData);
      localStorage.setItem('jwt', jwt);
      localStorage.setItem('type', orderData.type);
      this.setTotalPhotos(orderData.total_photos);
      this.stepManagerService.saveSteps(orderData.steps.filter(step => step.id !== 1 && step.id !== 2));
      this.sectionStepManagerService.saveSectionSteps(orderData.dynamic_sections);
      this.tourManagerService.saveAppTooltipsTargets(orderData);
      // this.getInspectionProgress();

      // Timer se inicializa en el resolver de las rutas de inspección
      // await this.initInspectionTimer();
      this.stepManagerService.navigateToStep(0);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async initInspectionTimer() {
    const jwt = localStorage.getItem('jwt');
    if (!jwt) {
      throw new Error('No JWT found');
    }
    const remainingTime = this.timerService.getRemainingTimeFromToken(jwt);
    if (remainingTime <= 0) {
      await this.handleInspectionExpired();
      return;
    }
    this.timerService.setTimer(remainingTime * 1000, this.handleInspectionExpired.bind(this));
  }

  public async isInspectionActive(): Promise<boolean> {
    const jwt = localStorage.getItem('jwt');
    if (!jwt) {
      return false;
    }
    const remainingTime = this.timerService.getRemainingTimeFromToken(jwt);
    return remainingTime > 0;
  }

  public async handleInspectionExpired() {
    await this.clearInspectionData();
    this.router.navigate(['/home'], {
      state: {
        message: 'La inspección ha expirado',
        messageType: AlertType.Error,
      }
    });
  }

  public async clearInspectionData(): Promise<void> {
    // await this.cacheService.clearDB();
    await this.DB.clearDB();
    localStorage.clear();
  }

  public setInspectionAsFinished(): void {
    localStorage.setItem('inspectionFinished', 'true');
  }

  public isInspectionFinished(): boolean {
    return localStorage.getItem('inspectionFinished') === 'true';
  }

  public isFixInspection(): boolean {
    const isFix = localStorage.getItem('type') === 'Fix';
    return isFix;
  }


  private setTotalPhotos(totalPhotos: number): void {
    localStorage.setItem('total_photos', totalPhotos.toString());
  }


}
