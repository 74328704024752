import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { EInspectionStepUrl } from 'src/app/interfaces/PWA/Inspection';
import { InspectionControlService } from 'src/app/services/InspectionHandlers/InspectionControl/inspection-control.service';
import { StepManagerService } from 'src/app/services/InspectionHandlers/StepManager/step-manager.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionCheck implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly inspectionControlService: InspectionControlService,
    private readonly stepManagerService: StepManagerService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.inspectionControlService.isInspectionActive().then((isInspectionActive) => {
        const isInspectionUrl = this.stepManagerService.getStepIdFromUrl(state.url as EInspectionStepUrl) !== undefined; 
        if (isInspectionActive) {
          if (isInspectionUrl) {
            resolve(true);
          } else {
            this.stepManagerService.navigateToStep(0);
            resolve(false);
          }
        } else {
          if (isInspectionUrl) {
            this.router.navigate(['/home']);
            resolve(false);
          } else {
            resolve(true);
          }
        }
      }
      ).catch((error) => {
        console.log('error', error);
        resolve(false);
      });
    });
  }

}
