
<div class="full-screen-media-modal">
  <app-icon-button
    class="close-btn"
    icon="close-outline"
    size="md"
    (click)="close()"
  >
  </app-icon-button>
  <div class="modal-media-container">
    @if (mediaType === 'photo') {
      <img [src]="mediaUrl" class="media" alt="media" />
    } @else if (mediaType === 'video') {
      <video controls class="media">
        <source [src]="mediaUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    }
  </div>
</div>