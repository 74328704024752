<div class="previous-answer-modal">
  <div class="previous-answer-modal-header">
    <app-important-text [text]="title" size="xl"></app-important-text>
    <app-icon-button icon="close" size="md" (click)="closeModal()"></app-icon-button>
  </div>
  <div class="review-container">
    <div class="review-item-container" *ngIf="inspectionHasFormStep">
      <button class="dropdown-button" (click)="toggleForms()">
        <h1 class="step-title">Formularios</h1>
        <ion-icon class="dropdown-icon" name="chevron-down-outline" *ngIf="!isFormsOpen"></ion-icon>
        <ion-icon class="dropdown-icon" name="chevron-up-outline" *ngIf="isFormsOpen"></ion-icon>
      </button>
      <div *ngIf="isFormsOpen" class="sections">
        @for (formSection of formAnswers; track $index) {
          @if ((getKeys(formSection.formElements).length || 0) > 0) {
            <div [ngClass]="{'section': true, 'section-even': $index % 2 === 0}">
              <div class="section-header">
                <h2 class="section-title">{{ formSection.title }}</h2>
                <button class="edit-item-btn" (click)="navigateToForms(formSection?.id || 0)">
                  <img src="assets/svg/edit-item.svg" alt="Editar">
                </button>
              </div>
              <div class="answers">
                <div *ngFor="let key of getKeys(formSection.formElements); let track = index" class="answer">
                  <h3>{{ formSection.formElements[key].label }}</h3>
                  <ng-container *ngIf="formSection.formElements[key].value">
                    <ng-container *ngIf="formSection.formElements[key].isPhoto">
                      <div class="file-container" *ngFor="let photoUrl of formSection.formElements[key].photos; let fileIndex = index">
                        <img [src]="photoUrl" alt="Archivo" class="media" />
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!formSection.formElements[key].isPhoto">
                      <p>{{ formSection.formElements[key].value }}</p>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!formSection.formElements[key].value">
                    <p class="no-response">No hay respuesta</p>
                  </ng-container>
                </div>
              </div>
            </div>
          }
        }
      </div>
    </div>
    <div class="review-item-container" *ngIf="inspectionHasPhotoStep">
      <button class="dropdown-button" (click)="togglePhotos()">
        <h1 class="step-title">Fotos</h1>
        <ion-icon class="dropdown-icon" name="chevron-down-outline" *ngIf="!isPhotosOpen"></ion-icon>
        <ion-icon class="dropdown-icon" name="chevron-up-outline" *ngIf="isPhotosOpen"></ion-icon>
      </button>
      <div *ngIf="isPhotosOpen" class="sections">
        <div *ngFor="let photoSection of photoAnswers; let $index = index" [ngClass]="{'section': true, 'section-even': $index % 2 === 0}">
          <div class="section-header">
            <h2 class="section-title">{{ photoSection.title }}</h2>
            <button class="edit-item-btn" (click)="navigateToPhotos(photoSection.photoId)">
              <img src="assets/svg/edit-item.svg" alt="Editar">
            </button>
          </div>
          <div class="answers">
            <div class="answer">
              <div class="file-container" *ngFor="let file of photoSection.files; let fileIndex = index">
                @if (file.ext === 'jpg' || file.ext === 'png') {
                  <img [src]="file.data" alt="Archivo" class="media"/>
                } @else if (file.ext === 'mp4') {
                  <video
                    [src]="file.data + '#t=0.5'"
                    class="media"
                    preload="metadata"
                    controls
                  >
                  Your browser does not support the video tag.
                </video>
                } @else if (file.ext === 'pdf') {
                  <div class="pdf-item">
                    <app-box-button
                      text="Descargar PDF"
                      icon="download"
                      type="primary"
                      (click)="downloadFile(photoSection.photoId, fileIndex)"
                    >
                    </app-box-button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>