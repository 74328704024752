<div class="ion-padding ion-text-center orientation-content">
  <ng-container *ngIf="!correctOrientation">
    <ion-icon
      [class]="orientationString()"
      name="phone-portrait-outline"
    ></ion-icon>
    <p>
      Porfavor, pon tu dispositivo en posición 
      {{ orientationString() === 'landscape' ? 'horizontal' : 'vertical' }} 
      para continuar.
    </p>
  </ng-container>
  <ng-container *ngIf="correctOrientation">
    <ion-icon color="success" name="checkmark-circle-outline"></ion-icon>
    <p>¡Muy bien!</p>
  </ng-container>
</div>