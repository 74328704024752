import { Injectable } from '@angular/core';
import { ApiService } from '../../General/Api/api.service';
import { lastValueFrom } from 'rxjs';
import {
  IAccesory,
  IAppEvaluation,
  IFinishInspection,
  IInspectionInstruction,
  IInspectionOrder,
  IPhoto,
} from 'src/app/interfaces/Outputs';
import { InspectionOrderNotFound } from 'src/app/errors/custom';
import { ISummary } from 'src/app/interfaces/Outputs/Summary';
import { IFeature } from 'src/app/interfaces/Outputs/Feature';
import { FormUserData } from 'src/app/interfaces/PWA/Forms';
import { Extension, Media } from 'src/app/interfaces/PWA/Files';
import { FileKeys } from 'src/app/interfaces/Outputs/FileKeys';
import { IBodyUploadFile } from 'src/app/interfaces/Inputs';
import { IPayloadToken } from 'src/app/interfaces/Global/PayloadToken';
import { jwtDecode } from 'jwt-decode';
import { ICarSelection } from 'src/app/interfaces/Outputs/CarSelection';
import { IDynamicFormElement } from 'src/app/interfaces/Outputs/DynamicFormElement';
import { IDeclareformElements } from 'src/app/interfaces/Inputs/DeclareFormElement';

@Injectable({
  providedIn: 'root',
})
export class InspectionApiService {
  constructor(private readonly apiService: ApiService) {}

  public getInspectionData(): IPayloadToken {
    const jwt = localStorage.getItem('jwt');
    if (!jwt) {
      throw new Error('No JWT found');
    }
    return jwtDecode(jwt) as IPayloadToken;
  }

  private async get(endpoint: string): Promise<any> {
    return await lastValueFrom(this.apiService.get(endpoint));
  }

  private async post(endpoint: string, body: any): Promise<any> {
    return await lastValueFrom(this.apiService.post(endpoint, body));
  }

  private async putExternal(url: string, body: any): Promise<any> {
    return await lastValueFrom(this.apiService.putExtenalUrl(url, body));
  }

  public async checkInspectionOrder(
    inspectionId: number
  ): Promise<IInspectionOrder> {
    try {
      const res = (
        await this.post('inspection/check/order', {
          inspection_id: inspectionId,
          firebase_token: 'jasdfklajskmrhzkqmrsh',
        })
      ).InspectionOrder as IInspectionOrder;
      return res;
    } catch (error) {
      throw new InspectionOrderNotFound('No existen inspecciones pendientes');
    }
  }

  // TODO: Tal vez pantalla de instrucciones y summary se podrían unificar
  public async getInspectionInstructions(
    inspectionId: number
  ): Promise<IInspectionInstruction[]> {
    const instructions = (
      await this.get(`resources/instruction?inspection_id=${inspectionId}`)
    ).InstructionScreen as IInspectionInstruction[];
    return instructions;
  }

  public async startInspectionOrder(orderData: IInspectionOrder): Promise<{jwt: string}> {
    try {
      // const orderData = await this.checkInspectionOrder();
      const inspectionTime = parseInt(orderData.timer_inspection as unknown as string);
      const startInspection = await lastValueFrom(
        this.apiService.post('inspection/start', {
          inspection_id: parseInt(orderData.id as unknown as string),
          inspection_time: inspectionTime,
          // inspection_time: 60000,
          email: orderData.email,
          inspection_type: orderData.type,
          whatsapp_number: orderData.whatsapp_number,
          whatsapp_text: orderData.whatsapp_text,
          phone_number: orderData.phone_number,
          business_hours: orderData.business_hours,
          business_week: orderData.business_week,
          rut: orderData.rut,
          car_plate: orderData.patente,
          name: orderData.name,
          last_name: orderData.lastname,
          url_logo: orderData.img_logo,
          id_ramo: orderData.id_ramo,
        })
      );
      return {
        jwt: startInspection.StartInspection
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getSummaryScreen(inspectionId: number): Promise<ISummary> {
    const summary = (
      await this.get(`resources/summary?inspection_id=${inspectionId}`)
    ).SummaryScreen as ISummary;
    return summary;
  }

  public async getCarSelectionScreen(): Promise<ICarSelection> {
    const carSelection = (await this.get(`resources/car-selection`))
      .CarSelectionScreen as ICarSelection;
    return carSelection;
  }

  public async getFeatureScreen(): Promise<IFeature> {
    const feature = (await this.get(`resources/feature`))
      .FeatureScreen as IFeature;
    return feature;
  }

  public async declareFeature(
    field_id: number,
    value: string
  ): Promise<void> {
    await this.post('inspection/declare/feature', {
      field_id,
      value,
    })
  }

  public async getAccesoryScreen(): Promise<IAccesory> {
    const accesory = (await this.get(`resources/accesory`))
      .AccesoryScreen as IAccesory;
    return accesory;
  }

  public async declareAccesory(
    field_id: number,
    value: string
  ): Promise<void> {
    // TODO: Quizás cambiar en el back a que todos los valores se puedan recibir como string,
    // TODO: Para permitir formularios dinámicos
    await this.post('inspection/declare/accesory', {
      field_id,
      value: value === 'true',
    });
  }

  public async getDynamicForm(
    section_id: number
  ): Promise<IDynamicFormElement[]> {
    const form = (await this.get(`resources/dynamic?section_id=${section_id}`))
      .DynamicForm as IDynamicFormElement[];
    console.log(form);
    return form;
  }

  public async declareFormElements(
    input: IDeclareformElements
  ): Promise<void> {
    await this.post('inspection/declare/form', input);
  }

  public async getPhotoScreen(): Promise<IPhoto> {
    const photo = (await this.get(`resources/photo`)).PhotoScreen as IPhoto;
    return photo;
  }

  public async getFileDeclareKeysAndPresignedUrls(exts: Extension[]): Promise<FileKeys> {
    const keys = await this.post('storage/signed', {
      exts: exts,
    }) as FileKeys;
    return keys;
  }

  public async uploadPhoto(url: string, data: Media): Promise<void> {
    await this.putExternal(url, data);
  }

  public async declarePhoto(props: IBodyUploadFile): Promise<void> {
    const res = await this.post('inspection/declare/file', props);
    console.log(res);
  }

  public async getFinishScreen(): Promise<IFinishInspection> {
    const finish = (await this.get(`resources/finish`))
      .FinishScreen as IFinishInspection;
    return finish;
  }

  public async declareFinalizeInspection(email: string): Promise<void> {
    await this.post('inspection/declare/finalize', {
      email,
    });
  }

  public async getEvaluationScreen(): Promise<IAppEvaluation> {
    const evaluation = (await this.get(`resources/evaluation`))
      .EvaluationScreen as IAppEvaluation;
    return evaluation;
  }
}
