<div class="permissions-modal full-height">
    <div class="permissions-modal-header">
        <svg-icon src="assets/svg/unlock.svg" alt="Unlock" class="svg-icon"></svg-icon>
        <p>
            <strong>Recuerda darnos accesos a los siguientes permisos:</strong>
        </p>
    </div>
    <div class="permissions-container full-height-scroll">
        <div class="permissions-list">
            @for (permission of getPermissions(); track permission) {
            <div class="permission-item">
                <ion-checkbox class="permission-checkbox" mode="md"
                    [disabled]="permissions[permission] === 'granted' || permissions[permission] === 'denied'"
                    [checked]="permissions[permission] === 'granted'"
                    [indeterminate]="permissions[permission] === 'denied'"
                    (ionChange)="requestPermission(permission)"></ion-checkbox>
                <div class="permission-label">
                    <p>{{ getPermissionText(permission) }}</p>
                    <ion-icon [name]="getPermissionIcon(permission)"></ion-icon>
                </div>
                @if (permissions[permission] === 'denied') {
                <p class="denied">El permiso ha sido denegado, por favor actívalo en la configuración de tu dispositivo
                    o
                    navegador.</p>
                }
            </div>
            }
        </div>
    </div>
    <p>
        @if (allPermissionsGranted) {
            ¡Listo! Ya puedes continuar.
        }
        @else {
            Asegúrate de darnos acceso a todos los permisos para continuar.
        }
    </p>
    <!--
        <p>Error: {{error | async}}</p>
        <p>permisos: {{permissions | json}}</p>
        <p>Veces que ha pasado por el observable de permisos: {{cambioPermiso}}</p>
        <p>Todos los permisos desbloqueados: {{allPermissionsGranted}}</p>
    -->
    <div class="confirm-button">
        <app-app-button (click)="closeModal()" [disabled]="!allPermissionsGranted" text="Siguiente"
            icon="chevron-forward">
        </app-app-button>
    </div>
</div>