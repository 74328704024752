import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AppDB } from 'src/app/config/idb';
import { InspectionOrderNotFound } from 'src/app/errors/custom';
import { InspectionIdNotProvided } from 'src/app/errors/global';
import { AlertType } from 'src/app/interfaces/Global/Alert';
import { EInspectionStepUrl } from 'src/app/interfaces/PWA/Inspection';
import { FormControlService } from 'src/app/services/General/FormControl/form-control.service';
import { InspectionApiService } from 'src/app/services/InspectionHandlers/InspectionApi/inspection-api.service';
import { TourManagerService } from 'src/app/services/InspectionHandlers/TourManager/tour-manager.service';
import { StylesService } from 'src/app/services/UIHandlers/Styles/styles.service';

@Injectable({
  providedIn: 'root',
})
export class InspectionOrderResolverService implements Resolve<any> {
  constructor(
    private readonly inspectionApiService: InspectionApiService,
    private readonly stylesService: StylesService,
    private readonly formControlService: FormControlService,
    private readonly AppDB: AppDB,
    private readonly router: Router
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    try {
      const inspection_id = route.queryParams.inspection_id;
      if (!inspection_id) {
        throw new InspectionIdNotProvided();
      }
      const inspectionOrder = await this.inspectionApiService.checkInspectionOrder(
        parseInt(inspection_id)
      );
      console.log(inspectionOrder);
      const summaryScreen = await this.inspectionApiService.getSummaryScreen(
        parseInt(inspection_id)
      );
      const instructions = await this.inspectionApiService.getInspectionInstructions(
        parseInt(inspection_id)
      )
      await this.stylesService.saveAppTheme(inspectionOrder);
      await this.stylesService.setAppTheme();
      this.formControlService.setHiddenFieldsToStorage(inspectionOrder.hidden_elements);
      await this.AppDB.clearDB();
      await this.AppDB.addFormSections(inspectionOrder.dynamic_sections);
      return {
        inspectionOrder,
        summaryScreen,
        instructions
      };
    } catch (error) {
      console.log(error);
      let message = '';
      if (
        error instanceof InspectionOrderNotFound || 
        error instanceof InspectionIdNotProvided
      ) {
        message = error.message;
      }
      else {
        message = 'Error al cargar pantalla de inicio';
      }
      this.router.navigate(['/general'], {
        state: {
          message: message,
          messageType: AlertType.Error,
        }
      });
    }
  }
}
