import { Injectable } from '@angular/core';
import { IDynamicSection } from 'src/app/interfaces/Outputs/DynamicSection';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SectionStepManagerService {

  public currentSectionIdx$ = new BehaviorSubject<number | null>(null);

  constructor() { }

  public saveSectionSteps(steps: IDynamicSection[]) {
    localStorage.setItem('sectionSteps', JSON.stringify(steps));
  }

  public getSectionSteps(): IDynamicSection[] {
    const steps = localStorage.getItem('sectionSteps');
    if (!steps) {
      throw new Error('Steps not found');
    }
    return JSON.parse(steps);
  }


  public getSectionStepByIdx(
    idx: number
  ): IDynamicSection {
    const steps = this.getSectionSteps();
    return steps[idx];
  }

  public getLastStepId() {
    const steps = this.getSectionSteps();
    return steps[steps.length - 1].id_seccion;
  }

  public setCurrentSectionIdx(idx: number) {
    console.log('Setting current section index to:', idx);
    this.currentSectionIdx$.next(idx);
  }

}
