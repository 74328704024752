import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { DeviceCheck } from './routing/guards/device-check/device-check';
import { InspectionCheck } from './services/Routing/Guards/InspectionCheck/inspection-check';
import { InspectionOrderResolverService } from './services/Routing/Resolvers/InspectionOrder/inspection-order-resolver.service';
import { AppThemeResolverService } from './services/Routing/Resolvers/AppTheme/app-theme-resolver.service';
import { InspectionInitResolverService } from './services/Routing/Resolvers/InspectionInit/inspection-init-resolver.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/general/home/home.module').then( m => m.HomePageModule),
    canActivate: [
      // DeviceCheck,
      InspectionCheck
    ]
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/general/start/start.module').then( m => m.StartPageModule),
    resolve: {
      data: InspectionOrderResolverService,
    },
    canActivate: [
      // DeviceCheck,
      InspectionCheck
    ]
  },
  {
    path: 'inspection',
    loadChildren: () => import('./layouts/inspection-layout/inspection-layout.module').then( m => m.InspectionLayoutModule),
    resolve: {
      currentIdx: InspectionInitResolverService,
      theme: AppThemeResolverService,
    },
    canActivate: [
      // DeviceCheck,
      InspectionCheck
    ]
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/reset/reset.module').then( m => m.ResetPageModule)
  },
  // El wildcard tiene que ser el último
  {
    path: '**',
    redirectTo: 'home'
  },  {
    path: 'dynamic',
    loadChildren: () => import('./pages/inpsection/dynamic/dynamic.module').then( m => m.DynamicPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
