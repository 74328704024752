import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { ImportantTextComponent } from "./important-text.component";



@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
    ],
    exports: [ImportantTextComponent],
    declarations: [ImportantTextComponent]
  })
export class ImportantTextModule {}