import { Component, Injectable, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject, Subscription, distinctUntilChanged, fromEvent, fromEventPattern, merge } from 'rxjs';
import { AppPermissions, TPermissionType } from 'src/app/interfaces/PWA/Permissions';
import { FileService } from '../File/file.service';
import { Geolocation } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private readonly permissionsSubject = new BehaviorSubject<AppPermissions>({
    camera: 'prompt',
    geolocation: 'prompt'
  });
  permissions$ = this.permissionsSubject.asObservable(); // Observable for real-time updates
  permissionsModalOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  intervalId: any;

  // Observable to merge all permission status change events
  permissionsChanges$: Observable<{ permission: TPermissionType; state: PermissionState }>;

  constructor(
    private readonly permissionsModalController: ModalController,
    private readonly fileService: FileService,
    private readonly platform: Platform,
  ) {
    this.initializePermissionChangeObservables();
  }

  private initializePermissionChangeObservables() {
    const permissionObservables: Observable<{ permission: TPermissionType; state: PermissionState }>[] = [];

    for (const permission of Object.keys(this.permissionsSubject.getValue()) as TPermissionType[]) {
      permissionObservables.push(this.createPermissionChangeObservable(permission));
    }

    merge(...permissionObservables).subscribe(({ permission, state }) => {
      this.updatePermissionState(permission, state);
      if (this.platform.is("ios")) {
        return;
      }
      if (state === 'denied' || state === 'prompt') {
        this.openPermissionsModal();
      }
    });
  }

  private createPermissionChangeObservable(permission: TPermissionType): Observable<{ permission: TPermissionType; state: PermissionState }> {
    return new Observable<{ permission: TPermissionType; state: PermissionState }>((observer) => {
      const emitPermissionState = async () => {
        const permissionStatus = await this.checkPermission(permission);
        if (permissionStatus) {
          if (permissionStatus.state) {
            observer.next({ permission, state: permissionStatus.state });
          }
        }
      };

      emitPermissionState();

      // Fallback: Poll periodically to detect state changes
      this.intervalId = setInterval(emitPermissionState, 5000); // Adjust polling interval as needed

      return () => clearInterval(this.intervalId);
    }).pipe(distinctUntilChanged());
  }

  private async checkPermission(permission: TPermissionType): Promise<Partial<PermissionStatus> | null> {
    try {
      return await navigator.permissions.query({ name: permission as PermissionName });
    } catch (error) {
      console.error(`Error checking permission for ${permission}:`, error);
      return null;
    }
  }

  private updatePermissionState(permission: TPermissionType, state: PermissionState) {
    const currentPermissions = { ...this.permissionsSubject.getValue() };
    currentPermissions[permission as keyof AppPermissions] = state;
    this.permissionsSubject.next(currentPermissions);
  }

  private async openPermissionsModal() {
    if (this.permissionsModalOpen$.value) return;

    this.permissionsModalOpen$.next(true);

    const modal = await this.permissionsModalController.create({
      component: PermissionsModalComponent,
      cssClass: 'permissions-modal',
      backdropDismiss: false,
    });

    modal.onDidDismiss().then(() => {
      this.permissionsModalOpen$.next(false);
    });

    await modal.present();
  }

  public async requestPermission(permission: TPermissionType) {
    clearInterval(this.intervalId);
    if (permission === 'camera') {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      this.fileService.stopStream(stream);
      //Ensure the camera permission is granted
      if (stream) {
        this.updatePermissionState('camera', 'granted');
        clearInterval(this.intervalId);
      } else {
        this.updatePermissionState('camera', 'denied');
      }
    } else if (permission === 'geolocation') {
      try {
        // navigator.geolocation.getCurrentPosition(
        //   (position: GeolocationPosition) => {
        //     this.updatePermissionState('geolocation', 'granted');
        //     clearInterval(this.intervalId);
        //     throw new Error('Geolocation error, lat: ' + position.coords.latitude + ', long: ' + position.coords.longitude);
        //   },
        //   (error) => {
        //     throw new Error('Geolocation error: ' + error.message);
        //   },
        //   { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        // );

        const position = await Geolocation.getCurrentPosition();

        if (position) {
          // Ensure the geolocation permission is granted
          this.updatePermissionState('geolocation', 'granted');
          clearInterval(this.intervalId);
        } else {
          this.updatePermissionState('geolocation', 'denied');
        }
      } catch (error) {
        this.updatePermissionState('geolocation', 'denied');
      }
    }
  }
}


@Component({
  selector: 'app-permissions-modal',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsModalComponent {

  permissions: AppPermissions = {
  } as AppPermissions;
  allPermissionsGranted = false;

  error = new BehaviorSubject<string>('No hay error');
  permissionValues: any;

  private readonly subscription: Subscription | null = null;

  constructor(
    private readonly modalController: ModalController,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit() {
    try {
      this.permissionsService.permissions$.subscribe(permissions => {
        this.permissions = permissions;
        this.allPermissionsGranted = this.checkAllPermissionsGranted(permissions);
      });
    }
    catch (error) {
      console.error('Error subscribing to permissions:', error);
      this.error.next(`Error al obtener los permisos: ${error}`);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  getPermissions(): (keyof AppPermissions)[] {
    return Object.keys(this.permissions) as (keyof AppPermissions)[];
  }

  async requestPermission(permission: TPermissionType) {
    try {
      await this.permissionsService.requestPermission(permission);
      // this.allPermissionsGranted = this.checkAllPermissionsGranted(this.permissions);
    } catch (error) {
      console.error('Error requesting permission:', error);
      // this.allPermissionsGranted = this.checkAllPermissionsGranted(this.permissions);
      this.error.next(`Error al solicitar permiso: ${error}`);
    }
  }

  checkAllPermissionsGranted(permissions: AppPermissions) {
    this.permissionValues = permissions;
    console.log(this.permissionValues);
    return Object.values(permissions).every(permission => permission === 'granted');
  }


  getPermissionText(permission: keyof AppPermissions) {
    switch (permission) {
      case 'camera':
        return 'Cámara';
      case 'geolocation':
        return 'Ubicación';
    }
  }

  getPermissionIcon(permission: keyof AppPermissions) {
    switch (permission) {
      case 'camera':
        return 'camera';
      case 'geolocation':
        return 'locate';
    }
  }


  closeModal() {
    this.modalController.dismiss();
  }
}
