
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import duration from "dayjs/plugin/duration";


dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

dayjs.extend(duration);

dayjs.locale("es");

const defaultTimeZone: string | undefined = "America/Santiago";
const isoFormat = "YYYY-MM-DD";
const isoFormatFull = "YYYY/MM/DD HH:MM:ss";


export function getCurrentUTCTimestamp(): number {
    return dayjs().utc().unix();
  }

export function formatMillisecondsToClock(milliseconds: number): string {
  return dayjs.duration(milliseconds).format("HH:mm:ss");
}

export function formatMillisecondsToMinutes(milliseconds: number): string {
  return dayjs.duration(milliseconds).format("mm:ss");
}
  