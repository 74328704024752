import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { IconButtonComponent } from "./icon-button.component";



@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
    ],
    exports: [IconButtonComponent],
    declarations: [IconButtonComponent]
  })
export class IconButtonModule {}