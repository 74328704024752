import { Component, Input, OnInit } from '@angular/core';
import { Size } from 'src/app/interfaces/PWA/UI';

@Component({
  selector: 'app-important-text',
  templateUrl: './important-text.component.html',
  styleUrls: ['./important-text.component.scss'],
})
export class ImportantTextComponent  implements OnInit {

  @Input() text: string = '';
  @Input() size: Size = 'lg';
  @Input() icon: string = '';

  constructor() { }

  ngOnInit() {}

}
