
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-full-screen-media-modal',
  templateUrl: './full-screen-media-modal.component.html',
  styleUrls: ['./full-screen-media-modal.component.scss'],
})
export class FullScreenMediaModalComponent {

  @Input() mediaType: 'photo' | 'video' = 'photo';
  @Input() mediaUrl = '';

  constructor(
    private readonly modalController: ModalController,
  ) { }

  close() {
    this.modalController.dismiss();
  }
}