import { Component, OnInit } from '@angular/core';
import { SwUpdateService } from './services/version_update/sw-update.service';
import { ActivationEnd, ActivationStart, NavigationEnd, NavigationStart, ResolveEnd, Router } from '@angular/router';
import { filter, map, merge, switchMap, tap } from 'rxjs';
import { OrientationService } from './services/General/Orientation/orientation.service';
import { TourService } from './services/tour/tour.service';
import { ITourStep } from './interfaces/PWA/Tour';
import { PermissionsService } from './services/General/Permissions/permissions.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  // currentTour: ITourStep[] = [];

  constructor(
    // private swUpdateService: SwUpdateService,
    // private router: Router,
    // private orientationService: OrientationService,
    // private inspectionService: InspectionService,
    // private tourService: TourService,
    private readonly permissionsService: PermissionsService,
  ) {
  }

  getNavEvent(){
    // return this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // );
  }

  setOrientationLock(){
    // this.getNavEvent().pipe(
    //   map(value => {
    //     const url = (value as NavigationEnd).url;
    //     this.orientationService.unlock();
    //     if (url === '/inspection/photo') {
    //       this.orientationService.lockScreen('landscape-primary');
    //     } else {
    //       this.orientationService.lockScreen('portrait-primary');
    //     }
    //   }),
    // ).subscribe();
  }

}
