export interface PhotoDisplay {
    title: string;
    photoId: number;
    files: FileDetailUrl[];
}


export interface PhotoStep {
    id?: number;
    photoId: number;
    photoName: string;
    hasSeenInstructions: boolean;
    files: FileDetail[];
}
  
export interface FileDetail {
    data: Media;
    ext: Extension;
}

export interface FileDetailUrl {
    data: string;
    ext: Extension;
}

export interface MediaPayload extends FileDetail {
    photoId: number;
}
  
export type Media = Blob | File;
export type Extension = 'jpg' | 'jpeg' | 'png' | 'mp4' | 'pdf';
export type ContentType = 'image/jpg' | 'video/mp4' | 'application/pdf';

export enum EExtension {
    jpg = 'jpg',
    jpeg = 'jpeg',
    png = 'png',
    mp4 = 'mp4',
    pdf = 'pdf',
}

export enum EContentType {
    jpg = 'image/jpg',
    png = 'image/png',
    jpeg = 'image/jpeg',
    mp4 = 'video/mp4',
    pdf = 'application/pdf',
}

export type ReferenceMediatype = 'photo' | 'video' | 'document';
  