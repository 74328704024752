import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { BoxButtonComponent } from "./box-button.component";



@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      IonicModule,
    ],
    exports: [BoxButtonComponent],
    declarations: [BoxButtonComponent]
  })
export class BoxButtonModule {}