import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  PermissionsModalComponent,
  PermissionsService,
} from "./permissions.service";
import { IonicModule } from "@ionic/angular";
import { ImportantTextModule } from "src/app/components/ui/important-text/important-text.module";
import { AppButtonModule } from "src/app/components/ui/app-button/app-button.module";
import { AngularSvgIconModule } from "angular-svg-icon";

@NgModule({
  imports: [
    CommonModule, 
    IonicModule,
    ImportantTextModule,
    AppButtonModule,
    AngularSvgIconModule
  ],
  declarations: [PermissionsModalComponent],
  providers: [PermissionsService]
})
export class PermissionsModule {}