import { EventEmitter, Injectable } from "@angular/core";
import Dexie, { Table } from "dexie";
import { FileDetail, Media, PhotoStep } from "src/app/interfaces/PWA/Files";
import { IDynamicSection } from "../interfaces/Outputs/DynamicSection";
import { FormElementPhotos, FormSection, FormUserData } from "../interfaces/PWA/Forms";
import { IStepProgress } from "../interfaces/PWA/Progress";
import { IInspectionOrderSteps } from "../interfaces/Outputs";

// Form Section Interface

@Injectable({
    providedIn: 'root'
})
export class AppDB extends Dexie {
    stepUpdate: EventEmitter<void> = new EventEmitter<void>();

    formSectionsTable!: Table<FormSection, number>;
    formElementPhotosTable!: Table<FormElementPhotos, number>;
    Photo!: Table<PhotoStep, number>;
    ProgressTable!: Table<IStepProgress, number>;

    constructor() {
        super('appdb');
        this.version(1).stores({
            formSectionsTable: '++id, formId, sectionName',
            formElementPhotosTable: '++id, elementId',
            Photo: '++id, photoId',
            ProgressTable: '++id, stepId'
        });
        this.on('populate', () => this.populate(this));
    }

    async populate(db: AppDB) {
        // await db.Photo.bulkAdd([
        //     { id: 3, title: 'Fotos', userData: null, progress: 0 },
        // ]);
    }

    // Esto lo ocuparemos por ahora para testing, y luego para eliminar datos cuando se acabe la inspeccion
    async clearDB() {
        console.log('deleting DB...');
        this.close();
        await this.delete();
        await this.open();
        console.log('DB deleted.');
    }

    async resetDB() {
        console.log('resetting DB...');
        await this.clearDB();
        console.log('DB reset.');
    }

    // Para inicializar la base de datos con las secciones del formulario
    async addFormSections(formSections: IDynamicSection[]) {
        const formSectionCacheData: FormSection[] = formSections.map((formSection) => ({
            formId: formSection.id_seccion,
            sectionName: formSection.nombre_seccion,
            completed: false,
            formElements: {},
          }));
        
          const existingSections = await this.formSectionsTable
            .where('formId')
            .anyOf(formSectionCacheData.map((section) => section.formId))
            .toArray();
        
          const existingFormIds = new Set(existingSections.map((section) => section.formId));
          const newSections = formSectionCacheData.filter(
            (section) => !existingFormIds.has(section.formId)
          );
        
          if (newSections.length > 0) {
            // Add only the new sections
            return this.formSectionsTable.bulkAdd(newSections);
          } else {
            console.log('No new sections to add');
            return Promise.resolve([]);
          }
    }

    async getFormSection(section_id: number) {
        return await this.formSectionsTable.where('formId').equals(section_id).first();
    }

    async addFormSectionElementResponse(
        section_id: number,
        formData: FormUserData
    ) {
        const formSection = await this.getFormSection(section_id);
        if (!formSection) {
            throw new Error(`Section with ID ${section_id} not found`);
        }
        formSection.formElements = formData;
        return this.formSectionsTable.put(formSection);
    }

    async setFormSectionCompleted(section_id: number) {
        const formSection = await this.getFormSection(section_id);
        if (!formSection) {
            throw new Error(`Section with ID ${section_id} not found`);
        }
        formSection.completed = true;
        return this.formSectionsTable.put(formSection);
    }

    async getAllFormSections() {
        return await this.formSectionsTable.toArray();
    }

    async getCompletedSections() {
        const sections = await this.getAllFormSections();
        return sections.filter((section) => section.completed);
    }

    async getFormSectionElementResponses(section_id: number): Promise<FormUserData> {
        const section = await this.getFormSection(section_id);
        return section ? section.formElements : {};
    }

    async getFormElementPhoto(elementId: number): Promise<FormElementPhotos | undefined> {
        const formElementPhotos = await this.formElementPhotosTable.where('elementId').equals(elementId).first();
        return formElementPhotos;
    }

    async addFormElementPhoto(elementId: number, photo: Media) {
        const existingEntry = await this.getFormElementPhoto(elementId);
        if (existingEntry) {
            const photos = existingEntry.photos;
            photos.push(photo);
            return this.formElementPhotosTable.put({ ...existingEntry, photos });
        } else {
            return this.formElementPhotosTable.add({ elementId, photos: [photo] });
        }
    }

    async getFormElementPhotos(elementId: number): Promise<Media[]> {
        const formElementPhotos = await this.getFormElementPhoto(elementId);
        return formElementPhotos ? formElementPhotos.photos : [];
    }

    // Para inicializar la base de datos con los pasos de la inspeccion
    async addProgress(steps: IInspectionOrderSteps[]) {
        const progressCacheData: IStepProgress[] = steps.map((step) => ({
            stepId: step.id,
            progress: 0,
        }));

        const existingSteps = await this.ProgressTable
            .where('stepId')
            .anyOf(progressCacheData.map((step) => step.stepId))
            .toArray();

        const existingStepIds = new Set(existingSteps.map((step) => step.stepId));
        const newSteps = progressCacheData.filter(
            (step) => !existingStepIds.has(step.stepId)
        );

        if (newSteps.length > 0) {
            return this.ProgressTable.bulkAdd(newSteps);
        } else {
            console.log('No new steps to add');
            return Promise.resolve([]);
        }
    }

    async getProgress(stepId: number) {
        return await this.ProgressTable.where('stepId').equals(stepId).first();
    }


    async getAllFiles() {
        return await this.Photo.toArray();
    }

    async getFileData(photoId: number) {
        return await this.Photo.where('photoId').equals(photoId).first();
    }

    async addFileData(
        photoId: number, 
        photoName: string,
        fileDetail: FileDetail
    ) {
        const existingEntry = await this.getFileData(photoId);
        if (existingEntry) {
            return this.Photo.put({ ...existingEntry, files: [...existingEntry.files, fileDetail] });
        } else {
            return this.Photo.add({ photoId, photoName, hasSeenInstructions: false, files: [fileDetail] });
        }
    }

    async deleteFileData(photoId: number, fileIdx: number) {
        const existingEntry = await this.getFileData(photoId);
        if (existingEntry) {
            const files = existingEntry.files;
            files.splice(fileIdx, 1);
            return this.Photo.put({ ...existingEntry, files });
        } else {
            throw new Error(`File data for photo ID ${photoId} not found`);
        }
    }


    async setHasSeenInstructions(
        photoId: number, 
        photoName: string,
        hasSeenInstructions: boolean
    ) {
        const existingEntry = await this.getFileData(photoId);
        if (existingEntry) {
            return this.Photo.put({ ...existingEntry, hasSeenInstructions });	
        } else {
            return this.Photo.add({ photoId, photoName, hasSeenInstructions, files: [] });
        }
    }

}