import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected url = environment.GATEWAY_HOST

  // TODO: Metadata de prueba. Implementar manejo de metadata
  // TODO: Ocupar axios
  constructor(protected http: HttpClient) {}

  // Devuelve el JWT almacenado en el localStorage
  getJwt(): string {
    return localStorage.getItem('jwt') || ''
  }

  get(endpoint: string): Observable<any> {
    const headers = this.getHeaders()
    return this.http.get(`${this.url}/api/${endpoint}`, {headers})
  }

  post(endpoint: string, body: any): Observable<any> {
    const headers = this.getHeaders()
    return this.http.post(`${this.url}/api/${endpoint}`, body, {headers})
  }

  putExtenalUrl(url: string, body: any): Observable<any> {
    return this.http.put(url, body)
  }

  getHeaders(json: boolean = true): HttpHeaders {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.getJwt()}`)
      .set('app_name', 'LET')
      .set('app_version', '8.1.0')
      .set('internet_provider', 'wifi')
      .set('phone_brand', 'iphone')
      .set('phone_model', '11')
      .set('phone_so', 'IOS')
      .set('platform', 'mobile')
    if (json) {
      headers.set('Content-Type', 'application/json')
    }
    return headers
  }

}
