import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { PreviousAnswerModalComponent } from "./previous-answer-modal.component";
import { ImportantTextModule } from "../../important-text/important-text.module";
import { IconButtonModule } from "../../icon-button/icon-button.module";
import { BoxButtonModule } from "../../box-button/box-button.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ImportantTextModule,
    IconButtonModule,
    BoxButtonModule,
  ],
  declarations: [PreviousAnswerModalComponent],
  exports: [PreviousAnswerModalComponent]
})
export class PreviousAnswerModalModule { }