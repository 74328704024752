import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonType } from 'src/app/interfaces/PWA/UI';

@Component({
  selector: 'app-app-button',
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss'],
})
export class AppButtonComponent  implements OnInit {

  @Input() text: string = '';
  @Input() type: ButtonType = 'primary';
  @Input() icon: string = '';
  @Input() back: boolean = false;
  @Input() disabled: boolean = false;
  buttonClasses = [
    'app-button',
  ]

  constructor() { }

  ngOnInit() {
    this.buttonClasses.push(`btn-${this.type}`);
    if (this.back) {
      this.buttonClasses.push('back');
    }
  }

}
