import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideHttpClient } from '@angular/common/http';
import { OrientationModule } from './services/General/Orientation/orientation.module';
import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { JoyrideModule } from 'ngx-joyride';
import { PermissionsModule } from './services/General/Permissions/permissions.module';
import { PreviousAnswerModalModule } from './components/ui/modal/previous-answer-modal/previous-answer-modal.module';
import { FullScreenMediaModalModule } from './components/ui/modal/full-screen-media-modal/full-screen-media-modal.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    OrientationModule,
    PermissionsModule,
    AngularSvgIconModule.forRoot(),
    // AngularSvgIconPreloaderModule.forRoot({
		// 	configUrl: './assets/json/icons.json',
		// }),
    JoyrideModule.forRoot(),
    PreviousAnswerModalModule,
    FullScreenMediaModalModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    provideHttpClient(),
    SvgIconRegistryService,
    // InspectionService,
    // StylesService,
    // Platform,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: configInspection,
    //   deps: [InspectionService, StylesService, Platform],
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
