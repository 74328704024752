import { Component, EventEmitter, Injectable, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject, debounceTime, distinctUntilChanged, fromEvent, map, merge, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { AppButtonModule } from 'src/app/components/ui/app-button/app-button.module';

@Injectable({
  providedIn: 'root'
})
export class OrientationService {
  public currentOrientation$: Observable<OrientationType>;
  public lockedOrientation$ = new BehaviorSubject<OrientationType | null>(null);
  public lockIsReady$ = new BehaviorSubject<boolean>(false);
  public hasScreenOrientationEvent = new BehaviorSubject(false);
  public lockSuccess = new BehaviorSubject(false);
  private updateEvent = new EventEmitter();
  private modalActive = false;
  public modalActive$ = new BehaviorSubject(false);

  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {
    const events = [
      this.updateEvent,
      fromEvent(document, 'visibilitychange'),
      fromEvent(document, 'fullscreenchange'),
      fromEvent(window, 'resize')
    ]

    if (screen.orientation) {    
      events.push(fromEvent(screen.orientation, 'change'));
      this.hasScreenOrientationEvent.next(true);
    }

    this.currentOrientation$ = merge(...events).pipe(
      debounceTime(500),
      map(() => this.checkOrientation())
    );

  }

  /** 
   * Calcula la relación de aspecto y devuelve OrientationType  
  */
  public checkOrientation(): OrientationType {
    // screen.orientation.type funciona, pero no en dispositivos más viejos
    // Por esto utilizaremos window.outerWidth y window.outerHeight
    const aspectRatio = window.outerWidth / window.outerHeight;
    return aspectRatio >= 1 ? 'landscape-primary' : 'portrait-primary';
  }

  /**
   * Fuerza al usuario a rotar la pantalla para que coincida con la orientación deseada
   * mostrando un modal cada vez que la orientación no coincide
   * y haciendo uso de la API de Orientación de Pantalla y Pantalla Completa
   * @param orientation - La orientación deseada
   */
  public lockScreen(orientation: OrientationType): void {

    this.lockedOrientation$.next(orientation);
    this.currentOrientation$
      .pipe(
        takeWhile(() => !!this.lockedOrientation$.value),
        distinctUntilChanged(),
      )
      .subscribe({
        next: value => {
          this.togglefullScreen();
          if (this.platform.is('desktop')) {
            this.lockIsReady$.next(true);
          } else if (!this.modalActive) {
            if (value === this.lockedOrientation$.value) {
              this.lockIsReady$.next(true);
            } else {
              this.lockIsReady$.next(false);
              this.modalActive = true; 
              this.modalActive$.next(true);
              this.showOrientationModal().then(() => {
                this.modalActive = false;
                this.modalActive$.next(false);
                this.lockIsReady$.next(true);
                this.togglefullScreen();
              })
            }
          }
        },
        complete: () => {
          this.lockIsReady$.next(false);
          if (this.isFullscreenActive()) {
            document.exitFullscreen().catch(err => console.warn(err));
          }
        }
      });
    this.updateEvent.emit();
  }

  /**
   * Desbloquea la pantalla si ha sido bloqueada
  */
  public unlock(): void {
    this.lockedOrientation$.next(null);
    this.updateEvent.emit();
  }

  /**
   * Chequea si el modo pantalla completa está activo
  */
  public isFullscreenActive(): boolean {
    const doc = document as any;
    return (
      doc.fullscreenElement ||
      doc.webkitFullscreenElement ||
      doc.mozFullScreenElement
    );
  }

  /**
   * Muestra un modal que solicita al usuario que rote el dispositivo
  */
  private async showOrientationModal(): Promise<boolean> {
    const modal = await this.modalController.create({
      component: OrientationModalComponent,
      animated: false,
      cssClass:  "orientationModal"
    })

    await modal.present();

    return new Promise<boolean>(resolve => {
      modal
        .onDidDismiss()
        .then(() => resolve(true))
        .catch(() => resolve(false));
    })
  }

  /**
   * Setea la vista del documento a pantalla completa si es posible
  */
  private async togglefullScreen(): Promise<boolean> {
    const doc = document.documentElement as any;
    const rfs =
      doc.requestFullscreen ||
      doc.webkitRequestFullScreen ||
      doc.mozRequestFullScreen ||
      doc.msRequestFullscreen;
    if (rfs) {
      try {
        await rfs.call(doc);
        return true;
      } catch (error) {
        console.log('Error toggling fullscreen', error);
        return false;
      }
    } else {
      return false;
    }
  }

  // Estos métodos no se utilizarán, para evitar lógicas distintas en dispositivos que no soporten screen.orientation
  // O que no tengan lock de pantalla
  /**
   * Setea la orientación y activa el modo pantalla completa dependiendo del soporte
   * @param orientation - La orientación deseada
  */
  private async setOrientation(orientation: OrientationType): Promise<boolean> {
    const fullscreen = await this.togglefullScreen();
    if (!fullscreen) {
      return false
    }
    const locked = await this.toggleScreenLock(orientation);
    return locked;
  }

  /**
   * Setea un bloqueo de orientación de pantalla si es posible
   *
   * @param orientation - the desired orientation
  */
  private async toggleScreenLock(
    orientation: OrientationType
  ): Promise<boolean> {
    const screen = window.screen as any;
    if (
      (screen.orientation || {}).type
    ) {
      try {
        await screen.orientation.lock(orientation);
        this.lockSuccess.next(true);
        return true;
      } catch (error) {
        console.log('Error locking screen orientation', error);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Component({
  selector: 'app-orientation-modal',
  templateUrl: './orientation.component.html',
  styleUrls: ['./orientation.component.scss'],
})
export class OrientationModalComponent implements OnInit {
  constructor(
    public orientation: OrientationService,
    private modalController: ModalController
  ) {}

  public dismiss$ = new Subject<void>();
  public correctOrientation = false;

  ngOnInit(): void {
    this.orientation.currentOrientation$
      .pipe(takeUntil(this.dismiss$))
      .subscribe({
        next: value => {
          this.correctOrientation = this.isCorrect(value);
          if (this.correctOrientation) {
            timer(2000).subscribe(() => this.modalController.dismiss());
          }
        },
        complete: () => this.modalController.dismiss()
      });
  }

  orientationString(): string {
    return this.orientation.lockedOrientation$.value!.split("-")[0];
  }

  isCorrect(value: OrientationType): boolean {
    return value === this.orientation.lockedOrientation$.value;
  }

  continueClicked(): void {
    this.dismiss$.next()
  }

}
