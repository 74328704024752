
export interface MediaCapabilitiesRank {
    zoom: boolean;
    torch: boolean;
    facingMode: boolean;
    width: number;
    height: number;
}



export interface CameraCapabilities {
    deviceId: string;
    capabilities: MediaCapabilitiesRank;
}

export interface CameraCapabilitiesStream extends CameraCapabilities {
    stream: MediaStream;
}

export enum REQUIRED_CAPABILITIES_DIMS {
    WIDTH = 1280,
    HEIGHT = 720,
}

export enum CAMERA_FACING_MODE {
    FACINMODE_FRONT = 'user',
    FACINMODE_BACK = 'environment',
}

export enum ZOOM_LEVELS {
    ZOOM_1 = 1,
    ZOOM_2 = 2,
}
