import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { IDynamicFormDependency } from 'src/app/interfaces/Outputs/DynamicFormDependency';
import { EDynamicFormElementType, IDynamicFormElement } from 'src/app/interfaces/Outputs/DynamicFormElement';

interface IFormElementBuilder {

}


@Injectable({
  providedIn: 'root'
})
export class FormElementBuilderService {
  private field: IDynamicFormElement | null = null;

  setField(
    field: IDynamicFormElement,
  ): this {
    this.field = field;
    return this;
  }

  build(
    fieldValue?: string
  ): FormControl {
    if (!this.field) {
      throw new Error('Campo no definido');
    }

    const validations = this.getElementValidators(this.field);

    const control = new FormControl(fieldValue || '', validations);
    return control;
  }

  getElementValidators(
    field: IDynamicFormElement
  ): ValidatorFn[] {
    const validations = [];
    if (field.requerido) {
      validations.push(Validators.required);
    }
    if (field.tipo_elemento === EDynamicFormElementType.TEXT) {
      validations.push(Validators.pattern(/^[a-zA-Z0-9 ]+$/));
    }
    if (field.tipo_elemento === EDynamicFormElementType.TEXT_AÑO) {
      validations.push(Validators.pattern(/^\d{4}$/));
    }
    if (field.tipo_elemento === EDynamicFormElementType.TEXT_CHASIS) {
      validations.push(Validators.pattern(/^\d{17}$/));
    }
    if (field.tipo_elemento === EDynamicFormElementType.TEXT_PATENTE) {
      validations.push(Validators.pattern(/^[A-Z]{2}\d{4}$/));
    }
    if (field.tipo_elemento === EDynamicFormElementType.TEXT_RUT) {
      validations.push(Validators.pattern(/^\d{7,8}-[0-9Kk]$/));
    }
    if (field.tipo_elemento === EDynamicFormElementType.TEXT_EMAIL) {
      validations.push(Validators.email);
    }
    if (field.tipo_elemento === EDynamicFormElementType.TEXT_FONO) {
      validations.push(Validators.pattern(/^\d{9}$/));
    }

    return validations;

  }
}
// export interface IDynamicFormElement {
//   id_elemento: number;
//   nombre_elemento: string;
//   id_seccion: number;
//   tipo_elemento: EDynamicFormElementType;
//   cantidad_fotos: number;
//   requerido: boolean;
//   mensaje_error: string;
//   orden: number | null;
//   habil: boolean;
//   values: IDynamicFormValue[];
//   dependency: IDynamicFormDependency | null;
// }

// export enum EDynamicFormElementType {
//   IMAGEN = 'IMAGEN',
//   TEXT = 'TEXT',
//   TEXT_AÑO = 'TEXT-AÑO',
//   TEXT_CHASIS = 'TEXT-CHASIS',
//   TEXT_PATENTE = 'TEXT-PATENTE',
//   TEXT_RUT = 'TEXT-RUT',
//   SELECT = 'SELECT',
//   COMBO = 'COMBO',
// }
