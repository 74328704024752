import { Injectable } from '@angular/core';
import { EInspectionStep } from 'src/app/interfaces/PWA/Inspection';
import { InspectionControlService } from '../InspectionControl/inspection-control.service';
import { BehaviorSubject } from 'rxjs';
import { StepManagerService } from '../StepManager/step-manager.service';
import { RouteSharedDataService } from '../../Workarounds/RouteSharedData/route-shared-data.service';
import { IInspectionOrderSteps } from 'src/app/interfaces/Outputs';
import { AppDB } from 'src/app/config/idb';

@Injectable({
  providedIn: 'root',
})
export class ProgressService {
  public stepsProgress$: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >([]);

  constructor(
    private readonly stepManagerService: StepManagerService,
    private readonly routeSharedDataService: RouteSharedDataService,
    private readonly DB: AppDB
  ) {
    this.DB.stepUpdate.subscribe(() => {
      this.updateProgress();
    });
  }

  public async updateProgress() {
    const steps = this.stepManagerService.getSteps();
    const progress = await this.getAllStepsProgress(steps);
    this.stepsProgress$.next(progress);
  }

  public async setCurrentProgress(steps: IInspectionOrderSteps[]) {
    const progressValues = await this.getAllStepsProgress(steps);
    this.stepsProgress$.next(progressValues);
  }

  public async getAllStepsProgress(
    steps: IInspectionOrderSteps[]
  ): Promise<number[]> {
    const progressPromises = steps.map((step) => this.getStepProgress(step.id));
    const progressValues = await Promise.all(progressPromises);
    return progressValues;
  }

  private async getStepProgress(stepId: EInspectionStep): Promise<number> {
    if (stepId === EInspectionStep.Photo) {
      return await this.getPhotoProgress();
    } else if (stepId === EInspectionStep.Forms) {
      console.log("Getting forms progress");
      return await this.getFormsProgress();
    } else if (stepId === EInspectionStep.Finish) {
      return (await this.validateProgress([
        EInspectionStep.Forms,
        EInspectionStep.Photo,
      ]))
        ? 100
        : 0;
    }
    return 0;
  }

  private async validateProgress(stepIds: EInspectionStep[]): Promise<boolean> {
    const progressPromises = stepIds.map((stepId) =>
      this.getStepProgress(stepId)
    );
    const progressValues = await Promise.all(progressPromises);
    return progressValues.every((progress) => progress === 100);
  }


  // private async computeNewProgress(stepId: EInspectionStep): Promise<void> {
  //   let progress = 0;
  //   if (stepId === EInspectionStep.Photo) {
  //     progress = await this.getPhotoProgress();
  //   } else if (stepId === EInspectionStep.Forms) {
  //     progress = await this.getFormsProgress();
  //   }
  //   else {
  //     progress = 100;
  //   }
  // }

  private async getPhotoProgress(): Promise<number> {
    const photoData = await this.DB.getAllFiles();
    const responsesLength = photoData.length;
    const stepLength = await this.stepManagerService.getStepLength(
      {}, // Pass appropriate screenData if needed
      EInspectionStep.Photo
    );
    return Math.round((responsesLength / stepLength) * 100) || 0;
  }

  private async getFormsProgress(): Promise<number> {
    const completedSections = await this.DB.getCompletedSections();
    const completedLength = completedSections.length;
    const formSections = await this.DB.getAllFormSections();
    const formSectionsLength = formSections.length;
    const progress =
      Math.round((completedLength / formSectionsLength) * 100) || 0;
    return progress;
  }

  public async hasFinishedInspectionSteps(): Promise<boolean> {
    const finishProgress = await this.getStepProgress(EInspectionStep.Finish);
    return finishProgress === 100;
  }
}
