import { Component, OnInit } from '@angular/core';
import { StepManagerService } from 'src/app/services/InspectionHandlers/StepManager/step-manager.service';
import { ModalController } from '@ionic/angular';
import { Media, PhotoDisplay, PhotoStep } from 'src/app/interfaces/PWA/Files';
import { FileService } from 'src/app/services/General/File/file.service';
import { FormElementPhotos, FormSectionDataAndPhotos } from 'src/app/interfaces/PWA/Forms';
import { AppDB } from 'src/app/config/idb';
import { FormGalleryModalComponent } from '../form-gallery-modal/form-gallery-modal.component';
import { EInspectionStep, EInspectionStepUrl } from 'src/app/interfaces/PWA/Inspection';
import { IInspectionOrderSteps } from 'src/app/interfaces/Outputs';

@Component({
  selector: 'app-previous-answer-modal',
  templateUrl: './previous-answer-modal.component.html',
  styleUrls: ['./previous-answer-modal.component.scss'],
})
export class PreviousAnswerModalComponent implements OnInit {

  visibleAnswers: Set<number> = new Set<number>();
  title = `Respuestas anteriores`;
  formAnswers: FormSectionDataAndPhotos[] = [];
  photoAnswers: PhotoDisplay[] = [];
  inspectionHasFormStep: boolean;
  inspectionHasPhotoStep: boolean;
  isFormsOpen = false;
  isPhotosOpen = false;

  constructor(
    private readonly stepManagerService: StepManagerService,
    private readonly modalController: ModalController,
    private readonly fileService: FileService,
    private readonly db: AppDB
  ) { }

  async ngOnInit() {
    const steps = this.stepManagerService.getSteps();
    this.inspectionHasFormStep = steps.some(step => step.id === EInspectionStep.Forms);
    this.inspectionHasPhotoStep = steps.some(step => step.id === EInspectionStep.Photo);
    if (this.inspectionHasFormStep) {
      this.formAnswers = await this.stepManagerService.getFormAnswers();
    }
    if (this.inspectionHasPhotoStep) {
      this.photoAnswers = await this.stepManagerService.getPhotoAnswers();
    }
  }

  navigateToForms(sectionId: number) {
    this.stepManagerService.navigateToFormSection(sectionId-1);
    this.modalController.dismiss();
  }

  navigateToPhotos(photoId: number) {
    this.stepManagerService.navigateToPhotoId(photoId);
    this.modalController.dismiss();
  }

  navigateToStep(stepUrl: EInspectionStepUrl) {
    this.stepManagerService.navigateToStepUrl(stepUrl);
    this.modalController.dismiss();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  toggleForms() {
    this.toggleAnswer(EInspectionStep.Forms);
  }

  togglePhotos() {
    this.toggleAnswer(EInspectionStep.Photo);
  }

  toggleAnswer(stepId: EInspectionStep) {
    if (stepId === EInspectionStep.Forms) {
      this.isFormsOpen = !this.isFormsOpen;
    }
    if (stepId === EInspectionStep.Photo) {
      this.isPhotosOpen = !this.isPhotosOpen;
    }
  }

  isAnswerVisible(index: number): boolean {
    return this.visibleAnswers.has(index);
  }

  getKeys(obj: any): number[] {
    return Object.keys(obj).map(key => parseInt(key));
  }

  async downloadFile(
    photoId: number,
    fileIdx: number
  ) {
    const photoData = await this.db.getFileData(photoId);
    const files = photoData?.files;
    if (!files) {
      return;
    }
    const file = files[fileIdx];
    if (!file?.data) {
      return;
    }
    const fileName = `LET_${photoData.photoName}_${fileIdx + 1}.${file.ext}`;
    this.fileService.downloadFile(file, fileName);
  }

}
